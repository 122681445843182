<footer class="footer-area footer-bg2 pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img">
                            <img src="assets/img/logo/logo1.png" alt="Images">
                        </div>
                        <p>Technocrats, provides expert solutions in software engineering, cloud-native services, data analytics, fintech solutions, managed IT services, 
                            and other tech domains across a wide range of industries, including finance, manufacturing, telecom, and supply chain.</p>
                        <div class="footer-social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <!-- <li><a href="#" target="_blank"><i class='bx bxl-'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>  -->
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Expertise</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/data-analytics">Data Analytics</a></li>
                            <li><a routerLink="/business-intelligence">Business Intelligence</a></li>
                            <li><a routerLink="/data-warehouse-consulting">Data Warehousing</a></li>
                            <li><a routerLink="/aws-consulting">AWS Consulting</a></li>
                            <li><a routerLink="/informatica-cloud-services">Informatica Cloud</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Services</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/extended-team">Extended Team</a></li>
                            <li><a routerLink="/product-discovery">Discovery</a></li>
                            <li><a routerLink="/technology-consulting">Technology Consulting</a></li>
                            <li><a routerLink="/software-development">Software Development</a></li>
                            <li><a routerLink="/sql-server-managed-service">Managed Services</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Cloud Solutions</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/aws-consulting">AWS Consulting</a></li>
                            <li><a routerLink="/informatica-cloud-services">Informatica Cloud Services</a></li>
                            <li><a routerLink="/oracle-managed-service">Oracle Managed Service</a></li>
                            <li><a routerLink="/power-bi">Power BI</a></li>
                            <li><a routerLink="/tableau">Tableau</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="copy-right-text text-start">
                        <p>© Technocrats</p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="copy-right-list">
                        <!-- <ul>
                            <li><a routerLink="/privacy-policy"> Privacy Policy</a></li>
                            <li><a routerLink="/terms-conditions"> Terms & Conditions</a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>