import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sql-server-managed-service',
  templateUrl: './sql-server-managed-service.component.html',
  styleUrls: ['./sql-server-managed-service.component.scss']
})
export class SQLServerManagedServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
