<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3 style="text-align:left;">Complete, Professional & Flexible Remote Database Services</h3>
            <div style="color:white; text-align: left;">
                <h5 style="color:white; text-align: left;">
                    No matter how big or small your Database Infrastructure can be, Technocrats Remote DBA service will manage it up to your satisfaction.  
                    You’ll be able to focus on your business while our experts manage the database platform 24 x 7.
                </h5>
            </div>
        </div>
        
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="service-list-area pt-10 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Remote DBA Support</span>
            <h2>Why Technocrats Remote DBA?</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>INCREASE EFFICIENCY</h3>
                    <p>Technocrts Database experts work together with your team to monitor, support and improve database infrastructure.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>IMPROVE AVAILABILITY</h3>
                    <p>Technocrats offers 24×7 service coverage to improve availability of your critical production and non-production database infrastructure.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>INCREASE RESOURCE POOL</h3>
                    <p>With Technocrats DBA support, you do not need to worry about staff sick days, vacations, absences and employee turnover.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>REDUCE COSTS</h3>
                    <p>
                        With Technocrats DBA you could save up to 50% costs over in-house DBA while getting higher level of services 
                        and maintaining your system infrastructure.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>FAST RESPONSE TIME</h3>
                    <p>
                        Our staff will attend to database problems immediately, resolve incidents, 
                        investigate root cause and provide an incident report to minimise recurring incidents.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>EXPERIENCED CONSULTANTS</h3>
                    <p>You would get best consultants from Technocrats who are certified professionals, with years of experience in cutting-edge database technologies.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="project-area">
    <div class="container">
        <div class="project-content">
            <h2>Tailor-made database support solution for your specific business needs</h2>
            <a routerLink="/contact" class="project-btn">Get started <i class='bx bx-plus'></i></a>
        </div>
    </div>

    <div class="project-shape">
        <div class="shape1"><img src="assets/img/shape/shape17.png" alt=""></div>
        <div class="shape2"><img src="assets/img/shape/shape18.png" alt=""></div>
    </div>
</div>

<br /><br /><br />

<div class="faq-area pb-30">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Database Platforms</span>
            <h2 class="h2-color2">We support major database platforms</h2> 
        </div>

        <div class="row pt-45">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title active" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Oracle</a>
                            <div class="accordion-content show">
                                <div class="content-widget-area">
                                    <div class="content-widget-text">                                            
                                        <ul>
                                            <li><i class='bx bx-check'></i> Oracle 8i, 9i, 10g, 11g, 12c,18c, 19c</li>
                                            <li><i class='bx bx-check'></i> RAC, Dataguard, TDE</li>
                                            <li><i class='bx bx-check'></i> ASM, RMAN, OEM</li>
                                            <li><i class='bx bx-check'></i> Database migration</li>
                                            <li><i class='bx bx-check'></i> Cloud migration</li>
                                            <li><i class='bx bx-check'></i> Platform migrations</li>
                                            <li><i class='bx bx-check'></i> CPU, PSU and upgrades</li>
                                            <li><i class='bx bx-check'></i> Performance Tuning</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> SQL Server</a>
                            <div class="accordion-content">
                                <div class="content-widget-area">
                                    <div class="content-widget-text">                                            
                                        <ul>
                                            <li><i class='bx bx-check'></i> SQL Server 2005-2008/R2, 2012/14/16/17/19</li>
                                            <li><i class='bx bx-check'></i> SSRS, SSIS, SSAS, MDS, DQS</li>
                                            <li><i class='bx bx-check'></i> Replication, Mirroring, Log Shipping</li>
                                            <li><i class='bx bx-check'></i> Compression, TDE, Backups</li>
                                            <li><i class='bx bx-check'></i> AlwaysOn Availability Groups Replica</li>
                                            <li><i class='bx bx-check'></i> AlwaysOn Failover Clustering</li>
                                            <li><i class='bx bx-check'></i> Backup and Recovery</li>
                                            <li><i class='bx bx-check'></i> Upgrade, Migration and Patching</li>
                                            <li><i class='bx bx-check'></i> Performance Tuning</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> EBD PostgreSQL</a>
                            <div class="accordion-content">
                                <div class="content-widget-area">
                                    <div class="content-widget-text">                                            
                                        <ul>
                                            <li><i class='bx bx-check'></i> Oracle to EBD Postgres migration</li>
                                            <li><i class='bx bx-check'></i> Performance tuning</li>
                                            <li><i class='bx bx-check'></i> Enterprise Manager</li>
                                            <li><i class='bx bx-check'></i> Installation and configuration</li>
                                            <li><i class='bx bx-check'></i> Data Migration</li>
                                            <li><i class='bx bx-check'></i> EDB subscriptions</li>
                                            <li><i class='bx bx-check'></i> Backup and Recovery</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> MySQL / Maria DB</a>
                            <div class="accordion-content">
                                <div class="content-widget-area">
                                    <div class="content-widget-text">                                            
                                        <ul>
                                            <li><i class='bx bx-check'></i> Installation and configuration</li>
                                            <li><i class='bx bx-check'></i> Clustering and Replication</li>
                                            <li><i class='bx bx-check'></i> Performance tuning</li>
                                            <li><i class='bx bx-check'></i> Backup / Restore</li>
                                            <li><i class='bx bx-check'></i> Migration</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title active" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> 
                                BAU Activities</a>
                            <div class="accordion-content show">
                                <div class="content-widget-area">
                                    <div class="content-widget-text">                                            
                                        <ul>
                                            <li><i class='bx bx-check'></i> Database patching and upgrades</li>
                                            <li><i class='bx bx-check'></i> DR planning & execution</li>
                                            <li><i class='bx bx-check'></i> Monitor OS events, DB errors/warnings</li>
                                            <li><i class='bx bx-check'></i> Monitor and handle disk spaces, memory, I/O & CPU issues</li>
                                            <li><i class='bx bx-check'></i> Database backup/restore and configurations</li>
                                            <li><i class='bx bx-check'></i> Ad-hoc Query writing/execution</li>
                                            <li><i class='bx bx-check'></i> Security administration</li>
                                            <li><i class='bx bx-check'></i> Index & statistics maintenance</li>
                                            <li><i class='bx bx-check'></i> Application upgrades and patching</li>
                                            <li><i class='bx bx-check'></i> Agent job creation, execution and monitoring</li>
                                            <li><i class='bx bx-check'></i> Database backup/restore and refresh</li>
                                            <li><i class='bx bx-check'></i> Monitor scheduled maintenance tasks</li>
                                            <li><i class='bx bx-check'></i> Integrity checking</li>
                                            <li><i class='bx bx-check'></i> Assist with application upgrades and patching</li>
                                            <li><i class='bx bx-check'></i> Performance monitoring</li>
                                            <li><i class='bx bx-check'></i> Any other database support activities</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>   
            </div>
        </div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <h2 class="h2-color2">Do you know that Technocrats Remote DBAs are available around-the-clock, and they can handle all DBA tasks those in-house DBA usually performs?</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>EMERGENCY SUPPORT <span>1</span></h3>
                            <p>Every database outage would cost money, damage the reputation and impact user confidence. 
                                Technocrats Remote DBA will restore services, perform root cause analysis to minimise unplanned outages. </p>
                        </li>
                        <li class="text-end">
                            <h3>24X7 REMOTE DATABASE SUPPORT <span>2</span></h3>
                            <p>Technocrats Remote DBA assists to resolve database incidents and keep databases up and running ensuring availability. 
                                Our dedicated on-call DBA is ready to assist you 24 X7. </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>3</span>MANAGED DATABASE SERVICES</h3>
                            <p>Technocrats Managed Database Services will monitor your database environment, handle events & alarms, 
                                resolve incidents, manage problems and implement changes following ITIL Service Delivery model. </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>