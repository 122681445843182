import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-extended-team',
  templateUrl: './extended-team.component.html',
  styleUrls: ['./extended-team.component.scss']
})
export class ExtendedTeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
