import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SeoAgencyComponent } from './components/pages/seo-agency/seo-agency.component';
import { AiStartupComponent } from './components/pages/ai-startup/ai-startup.component';
import { MachineLearningComponent } from './components/pages/machine-learning/machine-learning.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { CaseStudyDetailsComponent } from './components/pages/case-study-details/case-study-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { DataAnalyticsComponent } from './components/pages/data-analytics/data-analytics.component';
import { TechnologyConsultingComponent } from './components/pages/technology-consulting/technology-consulting.component';
import { SoftwareDevelopmentComponent } from './components/pages/software-development/software-development.component';
import { DiscoveryComponent } from './components/pages/discovery/discovery.component';
import { ExtendedTeamComponent } from './components/pages/extended-team/extended-team.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';

import { DataWarehouseConsultingComponent } from './components/pages/data-warehouse-consulting/data-warehouse-consulting.component';
import { BusinessIntelligenceComponent } from './components/pages/business-intelligence/business-intelligence.component';
import { PowerBIComponent } from './components/pages/power-bi/power-bi.component';
import { TableauComponent } from './components/pages/tableau/tableau.component';
import { CloudSolutionsComponent } from './components/pages/cloud-solutions/cloud-solutions.component';
import { AWSConsultingComponent } from './components/pages/aws-consulting/aws-consulting.component';
import { InformaticaCloudServicesComponent } from './components/pages/informatica-cloud-services/informatica-cloud-services.component';
import { DatabasePerformanceTuningComponent } from './components/pages/database-performance-tuning/database-performance-tuning.component';
import { DatabasePatchingAndUpgradesComponent } from './components/pages/database-patching-and-upgrades/database-patching-and-upgrades.component';
import { DatabaseMonitoringAndHealthCheckComponent } from './components/pages/database-monitoring-and-health-check/database-monitoring-and-health-check.component';
import { DatabaseHighAvailabilityAndDisasterRecoveryComponent } from './components/pages/database-high-availability-and-disaster-recovery/database-high-availability-and-disaster-recovery.component';
import { RemoteDatabaseSupportComponent } from './components/pages/remote-database-support/remote-database-support.component';
import { DatabaseServicesComponent } from './components/pages/database-services/database-services.component';
import { OracleManagedServiceComponent } from './components/pages/oracle-managed-service/oracle-managed-service.component';
import { SQLServerManagedServiceComponent } from './components/pages/sql-server-managed-service/sql-server-managed-service.component';

const routes: Routes = [
    {path: '', component: SeoAgencyComponent},
    {path: 'ai-startup', component: AiStartupComponent},
    {path: 'machine-learning', component: MachineLearningComponent},
    {path: 'about', component: AboutComponent},
    {path: 'team', component: TeamComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'case-study', component: CaseStudyComponent},
    {path: 'case-study-details', component: CaseStudyDetailsComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'sign-in', component: SignInComponent},
    {path: 'sign-up', component: SignUpComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'services', component: ServicesComponent},
    {path: 'services-details', component: ServicesDetailsComponent},
    {path: 'data-analytics', component: DataAnalyticsComponent},
    {path: 'technology-consulting', component: TechnologyConsultingComponent},
    {path: 'software-development', component: SoftwareDevelopmentComponent},
    {path: 'extended-team', component: ExtendedTeamComponent},
    {path: 'discovery', component: DiscoveryComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'contact', component: ContactComponent},
    
    {path: 'data-warehouse-consulting', component: DataWarehouseConsultingComponent},
    {path: 'business-intelligence', component: BusinessIntelligenceComponent},
    {path: 'power-bi', component: PowerBIComponent},
    {path: 'tableau', component: TableauComponent},
    {path: 'cloud-solutions', component: CloudSolutionsComponent},
    {path: 'aws-consulting', component: AWSConsultingComponent},
    {path: 'informatica-cloud-services', component: InformaticaCloudServicesComponent},
    {path: 'database-performance-tuning', component: DatabasePerformanceTuningComponent},
    {path: 'database-patching-and-upgrades', component: DatabasePatchingAndUpgradesComponent},
    {path: 'database-monitoring-and-health-check', component: DatabaseMonitoringAndHealthCheckComponent},
    {path: 'database-high-availability-and-disaster-recovery', component: DatabaseHighAvailabilityAndDisasterRecoveryComponent},
    {path: 'remote-database-support', component: RemoteDatabaseSupportComponent},
    {path: 'database-services', component: DatabaseServicesComponent},
    {path: 'oracle-managed-service', component: OracleManagedServiceComponent},
    {path: 'sql-server-managed-service', component: SQLServerManagedServiceComponent},

    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }