<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3 style="text-align:left;">Oracle Managed Service</h3>
            <div style="color:white; text-align: left;">
                <h5 style="color:white; text-align: left;">
                    Our highest rated database managed services cover your databases 24X7, 365 days-a-year
                </h5>
                </div>
        </div>
        
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-left">

                    <div class="container-max">
                        <div class="services-content">
                            <div class="row">
                                <div class="col-lg-7 col-md-12">
                                    <h2>Oracle managed service from The Database Experts</h2>
                                    <p style="text-align:left">
                                        Our Oracle database managed service ensures that your systems are running immaculately, reducing strain on your business. 
                                        We are there for you 24×7, 365 days a year.
                                    </p>
                                    <h2>What is a managed service?</h2>
                                    <p style="text-align:left">
                                        “An outsourced service to a company with specific expertise in maintaining business systems, anticipating 
                                        needs and preventing business interruption whilst improving day to day business operations”
                                    </p>
                                </div>

                                <div class="col-lg-5 col-md-12">
                                    <div class="about-widget-img">
                                            <img src="assets/img/about/about-img4.png" alt="">
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">&nbsp;</div>
                    
                    <div class="project-area">
                        <div class="container">
                            <div class="project-content">
                                <h2>Tailor-made database support solution for your specific business needs</h2>
                                <a routerLink="/contact" class="project-btn">Get started <i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    
                        <div class="project-shape">
                            <div class="shape1"><img src="assets/img/shape/shape17.png" alt=""></div>
                            <div class="shape2"><img src="assets/img/shape/shape18.png" alt=""></div>
                        </div>
                    </div>    

                </div>
            </div>
        </div>
    </div>
</div>

<div class="progress-area pt-10 pb-20">
    <div class="container">
        <div class="text-center">
            <h2 class="h2-color">What should an Oracle database managed service do for your business?</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="progress-card pr-bg-color-1">
                    <img src="assets/img/progress-icon/progress-icon1.png" alt="Images">
                    <!-- <h3>28,998</h3> -->
                    <p>It should fulfil its role as an insurance policy and ensure that your business is protected, giving you peace of mind</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="progress-card pr-bg-color-2">
                    <img src="assets/img/progress-icon/progress-icon2.png" alt="Images">
                    <!-- <h3>600%</h3> -->
                    <p>Provide additional resources, allowing the business to focus on BAU</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3  offset-lg-0">
                <div class="progress-card pr-bg-color-3">
                    <img src="assets/img/progress-icon/progress-icon3.png" alt="Images">
                    <!-- <h3>50,234</h3> -->
                    <p>Identify constructive improvements for your business (ROI)</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-left">

                    <div class="container-max">

                        <div class="row">&nbsp;</div>

                        <div class="content-widget-area">

                            <div class="row"><h2>Why choose an Oracle database managed service from <span class="h2-color">Technocrats?</span></h2></div>

                            <div class="row">
                                <div class="col-lg-5 col-md-12">
                                    <div class="content-widget-text">  
                                                                      
                                        <p>
                                            Our Oracle database managed service is built around your requirements. Based upon 15 years of experience, 
                                            we deliver a service which will ensure the uptime and recoverability of your critical Oracle environments. 
                                            Using our knowledge and your key business objectives (such as Recovery Point Objectives (RPO) and 
                                            Recovery Time Objectives (RTO), we will structure our managed service to not only meet these requirements 
                                            but go beyond and provide improvements that will enrich your Oracle estate.                                        
                                        </p>
                                        <p>
                                            With a flexible approach to delivering this tailored service, we will work with your business to define the 
                                            level of service that is required. This may involve taking total ownership of your Oracle estate, or it 
                                            could simply be working alongside your technical staff; becoming an extension of your existing team.
                                        </p>
                                        <p>
                                            Technocrats can provide guarantees around uptime and maintaining performance, utilising the extensive experience 
                                            of our Oracle consultants. Our consultants have in-house tools, specifically designed to maintain Oracle databases.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-lg-7 col-md-12">
                                    <div class="about-widget-img" style="padding-top:80px;">
                                        <img src="assets/img/our-approach.png" alt="our-approach">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="content-widget-text">  
                                        <p>
                                            This has been confirmed with 99% of customers renewing for more than 12 years… The service is scalable to meet 
                                            the needs of SMB to Enterprise companies, or becoming a valued partner to independent software vendors ensuring 
                                            greater software application performance with improved database stability.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container-fluid">
                <div class="services-left">
                    
                    <div class="project-area">
                        <div class="container">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <h2 style="color:white">Working with SME (small to medium enterprises) to enterprise businesses</h2>
                                        <a routerLink="/contact" class="call-btn">Get started <i class='bx bx-plus'></i></a>
                                    </div>
                                    <div class="col-lg-8 col-md-8">
                                        <div class="faq-area pb-70">
                                            <div class="container">                                        
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="my-accordion">
                                                            <ul class="accordion">
                                                                <li class="accordion-item">
                                                                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> What are the benefits of an Oracle Database Managed Service?</a>
                                                                    <div class="accordion-content">
                                                                        <p>
                                                                            Having an Oracle Managed Service with Technocrats yields a range of benefits, enabling your business to 
                                                                            reduce operational cost, conserve capital budget and lower IT expenditure; whilst improving 
                                                                            business-as-usual operations. This causes your Oracle estate to perform more efficiently, 
                                                                            with immense resilience and recoverability.This is achieved through:
                                                                        </p>
                                                                        <ul>
                                                                            <li>
                                                                                Outsourcing the responsibility of Oracle administration, maintenance and security for 24x7 operation; 
                                                                                freeing up your internal Oracle resources to focus on strategic innovation.
                                                                            </li>
                                                                            <li>
                                                                                Providing rapid scalability to support and manage growth spurts, or the additional technical knowledge 
                                                                                to assist in the continual development of your Oracle systems; becoming a flexible extension to your IT team.
                                                                            </li>
                                                                            <li>
                                                                                Minimising detrimental and expensive downtime, by improving system availability of your Oracle estate; 
                                                                                with our proactive and Oracle dedicated monitoring tools.
                                                                                <ul>
                                                                                    <li>
                                                                                        Assistance with vendor management being an interface for 3rd parties:
                                                                                    </li>
                                                                                    <li>
                                                                                        My Oracle Support (MOS) - Ensuring Service Requests (SR) are managed and resolved promptly.
                                                                                    </li>
                                                                                    <li>
                                                                                        Independent Software Vendors (ISV) - Supporting you with application issues, which requires 
                                                                                        in-depth technical knowledge of Oracle.
                                                                                    </li>
                                                                                    <li>
                                                                                        Data Centre Outsourcing (DCO) - Assisting your DCO to identify and resolve infrastructure 
                                                                                        issues which are affecting your Oracle estate.
                                                                                    </li>
                                                                                </ul>
                                                                            </li>
                                                                            <li>
                                                                                Regular Oracle database health checks are included within your Oracle Managed service. This provides 
                                                                                a system-wide review of your critical Oracle environments. This is a comprehensive report with 
                                                                                performance and data trending analysis, with best practice recommendations on areas for consideration 
                                                                                or improvement.
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                        
                                                                <li class="accordion-item">
                                                                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> What if I require 24x7 management for my Oracle databases?</a>
                                                                    <div class="accordion-content">
                                                                        <p>This can happen due to several reasons:</p>
                                                                        <ul>
                                                                            <li>
                                                                                Customers may require extended access and support
                                                                            </li>
                                                                            <li>
                                                                                Your business has grown to include international or worldwide markets
                                                                            </li>
                                                                        </ul>
                                                                        <p>
                                                                            Moving to a 24x7 operation for your systems can trigger an array of complications. This may include 
                                                                            extending your support structure and technical teams. Technocrats can assist in switching to 24x7, 
                                                                            ensuring your Oracle databases are resilient and available around the clock, without major investment 
                                                                            in staffing or expensive monitoring tools. Our service comprehensively covers your system and is 
                                                                            backed up by an extremely responsive and dependable team of experienced Oracle professionals, 24x7.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                        
                                                                <li class="accordion-item">
                                                                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Do I need to recruit an Oracle database administrator (DBA)?</a>
                                                                    <div class="accordion-content">
                                                                        <p>
                                                                            When a member of your Oracle DBA team leaves for whatever reason, this can result in a lot of time 
                                                                            and investment; finding a suitable replacement. This can sometimes be without any notice period, 
                                                                            leaving your business at risk. Technocrats can help you through the interview process, 
                                                                            ensuring you obtain the best candidate. Alternatively, Technocrats can also take over that 
                                                                            responsibility quickly and efficiently, giving you confidence that your Oracle databases will be 
                                                                            fully available and well-maintained. Subject to certain factors, this can often be beneficial 
                                                                            to your business, due to better cost-effectiveness.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                        
                                                                <li class="accordion-item">
                                                                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Can I get help with Oracle database support?</a>
                                                                    <div class="accordion-content">
                                                                        <p>
                                                                            Are you taking on a new product, or have a legacy application that runs on Oracle; but have 
                                                                            limited in house skills?
                                                                        </p>
                                                                        <p>
                                                                            An Oracle Managed Service will provide you with assurances the Oracle database environment is built 
                                                                            and configured for  optimal performance and resilience.
                                                                        </p>
                                                                        <p>
                                                                            This is achieved by Technocrats via an in-depth database onboarding process, utilising Technocrats' 
                                                                            team of Oracle professionals, whose knowledge and experience can identify known issues affecting 
                                                                            production databases and provide best practice recommendations on how to resolve them. 
                                                                            Then following a structured change control process, these recommendations can be implemented, 
                                                                            minimising the possibility of costly downtime.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                        
                                                                <li class="accordion-item">
                                                                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> 
                                                                        My Oracle estate is growing, how can Technocrats help?</a>
                                                                    <div class="accordion-content">
                                                                        <p>
                                                                            If your business is going through a period of growth and requires additional Oracle 
                                                                            Database Administrator resources to assist in the transition, our Oracle Managed Service is 
                                                                            available to either:
                                                                        </p>
                                                                        <ul>
                                                                            <li>
                                                                                Take some/all of the load from your internal Oracle team, allowing them to focus on 
                                                                                improvement projects.
                                                                            </li>
                                                                            <li>
                                                                                Provide additional support to meet the changing needs of the business i.e. 24x7 or holiday cover.
                                                                            </li>
                                                                        </ul>
                                                                        <p>
                                                                            The Technocrats established managed services offering can be tailored to meet the growing needs of 
                                                                            your business, offering 24x7 or 9-5 cover for both production and non-production Oracle environments.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    
                        <!-- <div class="project-shape">
                            <div class="shape1"><img src="assets/img/shape/shape17.png" alt=""></div>
                            <div class="shape2"><img src="assets/img/shape/shape18.png" alt=""></div>
                        </div> -->
                    </div>    

                </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>