<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3 style="text-align:left;">Data Analytics</h3>
            <div style="color:white; text-align: left;">
                <h5 style="color:white; text-align: left;">Optimize operations, mitigate risks, and turn your data into revenues with a trusted
                partner by your side. We can cover all your data needs, from data strategy design to
                cloud migration or implementation of Machine Learning and AI.</h5>
                </div>
            <!-- <ul> 
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Data Analytics</li>
            </ul> -->
        </div>
        
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <!-- <img src="assets/img/service/service-details.jpg" alt="Images"> -->
                        <h2>Benefit from Data Analytics</h2>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                             dolore plicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut quia consequuntur magni dolores
                              eos qui ratione voluptatem sequi nesciunt.</p> -->
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Create a data strategy that is future-proof.</h3>
                                <p>
                                    Technocrats can assist you with creating an efficient data strategy from scratch, analyzing your current 
                                    infrastructure, and overcoming obstacles so that you can meet all of your current and future data needs.                                   
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Data migration to the cloud</h3>
                                <p>
                                    Utilize the cloud's limitless computing and storage capacity to save expenses, boost scalability, increase data 
                                    security, and broaden your customer base. To increase business flexibility and avoid vendor lock-in, use a cloud-agnostic architecture strategy.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Utilize a data platform to streamline operations</h3>
                                <p>
                                    To integrate all disconnected systems, optimize data flow (ingestion, ETL, etc.), and promote effective 
                                    teamwork between your teams, our experts will create a data platform.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Set up efficient data governance</h3>
                                <p>
                                    Our experts will set up data governance processes, accesses, and responsibilities to enhance the quality 
                                    and security of data within your organization.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Create or expand your data team with top professionals.</h3>
                                <p>
                                    Create a development team from scratch or grow your internal staff by adding data specialists who offer advanced analytics services.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>AI can be used to automate and improve data analysis.</h3>
                                <p>
                                    Our experts will assist you in putting into practice ML/AI models that automate analytics, lessen manual labor, eliminate human error.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>

                    <h3>Create, implement, or enhance your data strategy</h3>

                    <p>Create a solid foundation for all of your data analytics efforts. Use our data analytics services to redesign your current data strategy or 
                        create a new one from scratch. In order to assist you meet all of your present and future data needs, we may also evaluate your current infrastructure.</p>

                        <div class="row">&nbsp;</div>

                        <div class="content-widget-area">
                            <div class="row">
                                <div class="col-lg-7 col-md-12">
                                    <div class="content-widget-img">
                                        <img src="assets/img/service/service-details-img2.png" alt="Images">
                                    </div>
                                </div>
    
                                <div class="col-lg-5 col-md-12">
                                    <div class="content-widget-text">
                                        <h3>Designing a data and analytics strategy:</h3>
                                        <br />
                                        <ul>
                                            <li><i class='bx bx-check'></i> Defining the scope and objectives</li>
                                            <li><i class='bx bx-check'></i> Putting data at the heart of each design choice</li>
                                            <li><i class='bx bx-check'></i> Aligning with your objectives and vision</li>
                                            <li><i class='bx bx-check'></i> Implementing collaborative data governance</li>
                                            <li><i class='bx bx-check'></i> Risk mitigation</li>
                                            <li><i class='bx bx-check'></i> Assisting in achieving a higher return on investment</li>
                                            <li><i class='bx bx-check'></i> Avoiding the need for costly development changes</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">&nbsp;</div>

                        <div class="content-widget-area">
                            <div class="row">
    
                                <div class="col-lg-6 col-md-12">
                                    <div class="content-widget-text">
                                        <h3>Strategy consulting and evaluation for data and analytics:</h3>
                                        <br />
                                        <ul>
                                            <li><i class='bx bx-check'></i> Creating the final product vision</li>
                                            <li><i class='bx bx-check'></i> Making project plans, timelines, and milestones</li>
                                            <li><i class='bx bx-check'></i> Estimating and creating functional decomposition</li>
                                            <li><i class='bx bx-check'></i> Designing CI/CD pipelines and proposing high-level architecture</li>
                                            <li><i class='bx bx-check'></i> Proposing team size, composition, and technology stack</li>
                                            <li><i class='bx bx-check'></i> Operations and support composition</li>
                                            <li><i class='bx bx-check'></i> Cost estimation</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="content-widget-img">
                                        <img src="assets/img/contact-img.png" alt="Images">
                                    </div>
                                </div>
                            </div>
                        </div> 

                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <div class="widget-category">
                        <h3>Other Services</h3>

                        <ul>
                            <li><a routerLink="/services-details">AI & ML Development</a></li>
                            <li><a routerLink="/services-details">Data Visualization</a></li>
                            <li><a routerLink="/services-details">Data Science</a></li>
                            <li><a routerLink="/services-details">Content Marketing</a></li>
                            <li><a routerLink="/services-details">SEO & Internet</a></li>
                        </ul>
                    </div>

                    <div class="contact-widget">
                        <h2>Contact Info</h2>

                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Phone: </h3>
                                    <span><a href="tel:+564325677896">+56432 567 7896</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a href="mailto:hello@ribo.com">hello@ribo.com</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-planet-earth"></i>
                                <div class="content">
                                    <h3>Location:</h3>
                                    <span>112/7 New York, USA</span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="services-widget-two">
                        <h2>Brochures</h2>

                        <ul>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>01:</h3>
                                    <span><a routerLink="/services-details">PDF Download</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>02:</h3>
                                    <span><a routerLink="/services-details">Services Details.txt</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

                   

<div class="data-table-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="data-table-content">
                    <h2>Design a future-proof data strategy</h2>
                    <a routerLink="/contact" class="default-btn">Get started <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="data-table-img">
                    <img src="assets/img/mission-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>