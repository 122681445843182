<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3 style="text-align:left;">Product Discovery</h3>
            <div style="color:white; text-align: left;">
                <h5 style="color:white; text-align: left;">
                    Build a prototype of your product, evaluate its usability, and validate your business model. 
                    The ultimate scope of your solution will be shaped by our specialists, who will also evaluate all 
                    presumptions from the viewpoints of business analysis, user experience, and information architecture, 
                    as well as provide the deliverables necessary for a successful project launch.
                </h5>
                </div>
            <!-- <ul> 
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Data Analytics</li>
            </ul> -->
        </div>
        
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-left">
                    <div class="container-max">
                        <div class="services-content">
                            <h2>Make sure your product succeeds.</h2>
                            <p style="text-align:left">
                                A group of committed Technocrats professionals mold the ultimate scope of your solution throughout the discovery phase.
                                It is founded on the user journey map, market research, and the coordinated aims of many corporate stakeholders.
                                The team also verifies all presumptions and hypotheses from several angles, including business analysis, user 
                                experience, and IT architecture. Additionally, it generates all the deliverables needed to successfully 
                                begin the implementation phase.
                            </p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="services-content-card">
                                    <h3>Create a new product from scratch.</h3>
                                    <p>
                                        Learn about your prospective project, identify the risks, link your company goals with particular 
                                        user demands, and design ideal experiences to satisfy them.                          
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="services-content-card">
                                    <h3>Perform a technical evaluation</h3>
                                    <p>
                                        Our specialists can do a complex independent audit of your company's existing systems prior to 
                                        an acquisition, merger, or substantial technology upgrades.
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="services-content-card">
                                    <h3>Extend the sophisticated existing system</h3>
                                    <p>
                                        Investigate the existing status of the system, determine the extent of the transformation, 
                                        identify possible bottlenecks and hazards, and devise solutions to solve them.
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="services-content-card">
                                    <h3>Examine your existing software.</h3>
                                    <p>
                                        Product Discovery will assist you in understanding the outdated components of your system, 
                                        realizing the full potential of the update, and planning the procedure for the best possible output.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>

                    <div class="project-area">
                        <div class="container">
                            <div class="project-content">
                                <h2>Test your product concept, optimize costs, and mitigate risks</h2>
                                <a routerLink="/contact" class="project-btn">Get started <i class='bx bx-plus'></i></a>
                            </div>
                        </div>

                        <div class="project-shape">
                            <div class="shape1"><img src="assets/img/shape/shape17.png" alt=""></div>
                            <div class="shape2"><img src="assets/img/shape/shape18.png" alt=""></div>
                        </div>
                    </div>
                    

                    <div class="row">&nbsp;</div>

                    <br />

                    <div class="container-max">
                    <h3>The People Behind Successful Product Discovery</h3>

                    <p>
                        A team of devoted professionals performs the Discovery Phase at Technocrats (the Solution Group). The following people make up the team:
                    </p>

                        <div class="row">&nbsp;</div>

                        <div class="content-widget-area">
                            <div class="row">
                                <div class="col-lg-5 col-md-12">
                                    <div class="about-widget-img">
                                        <img src="assets/img/about/about-img4.png" alt="">
                                    </div>
                                </div>
    
                                <div class="col-lg-7 col-md-12">
                                    <div class="content-widget-text">
                                        
                                        <ul>
                                            <li><i class='bx bx-check'></i> Solution Architect</li>
                                            <li><i class='bx bx-check'></i> UX Professional</li>
                                            <li><i class='bx bx-check'></i> Business Analyst</li>
                                            <li><i class='bx bx-check'></i> Project Manager</li>
                                            <li><i class='bx bx-check'></i> Technology Expert</li>
                                        </ul>
                                        <br/>
                                        <p>
                                            When further expertise is required, other experienced Technocrats employees are brought in.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">&nbsp;</div>

                        <div class="service-list-area pt-100 pb-70">
                            <div class="container">
                                <div class="section-title text-center">
                                    <span class="sp-before sp-after">Technocrats</span>
                                    <h2>Process of Product Discovery</h2>
                                </div>
                        
                                <div class="row pt-45">
                                    <h2>1. Preparation time (off-site)</h2>
                                    <p>We examine the basic needs and devise the best discovery strategy.</p>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="service-list-content">
                                            <h3>Analysis of Business</h3>
                                            <ul>
                                                <li><i class='bx bx-check'></i> Investigate the client's business environment.</li>
                                                <li><i class='bx bx-check'></i> Perform market research</li>
                                                <li><i class='bx bx-check'></i> Create a thorough plan for implementing the discovery.</li>
                                            </ul>
                                        </div>
                                    </div>
                        
                                    <div class="col-lg-4 col-md-6">
                                        <div class="service-list-content">
                                            <h3>Architecting Solutions</h3>
                                            <ul>
                                                <li><i class='bx bx-check'></i> Examine the needs and stated company goals.</li>
                                                <li><i class='bx bx-check'></i> Examine technology landscape</li>
                                                <li><i class='bx bx-check'></i> Make a preliminary list of the architectural restrictions and issues.</li>
                                            </ul>
                                        </div>
                                    </div>
                        
                                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                                        <div class="service-list-content">
                                            <h3>UX Design</h3>
                                            <ul>
                                                <li><i class='bx bx-check'></i> Conduct market research with emphasis on user experience</li>
                                                <li><i class='bx bx-check'></i> Examine Competitors</li>
                                                <li><i class='bx bx-check'></i> Improve your discovery strategy using UX initiatives</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                        
                                <div class="row pt-45">
                                    <h2>2. In-depth study (online and on-site)</h2>
                                    <p>To clearly identify your vision, goals, and success criteria, go in-depth with your business and technical study.</p>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="service-list-content">
                                            <h3>Analysis of Business</h3>
                                            <ul>
                                                <li><i class='bx bx-check'></i> Revise business goals and vision</li>
                                                <li><i class='bx bx-check'></i> Create business processes</li>
                                                <li><i class='bx bx-check'></i> Develop and specify priorities</li>
                                            </ul>
                                        </div>
                                    </div>
                        
                                    <div class="col-lg-4 col-md-6">
                                        <div class="service-list-content">
                                            <h3>Architecting Solutions</h3>
                                            <ul>
                                                <li><i class='bx bx-check'></i> Examine the functional needs</li>
                                                <li><i class='bx bx-check'></i> Consider limitations and issues</li>
                                                <li><i class='bx bx-check'></i> Develop your quality attributes</li>
                                            </ul>
                                        </div>
                                    </div>
                        
                                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                                        <div class="service-list-content">
                                            <h3>UX Design</h3>
                                            <ul>
                                                <li><i class='bx bx-check'></i> Establish the User Persona</li>
                                                <li><i class='bx bx-check'></i> Develop a user journey map</li>
                                                <li><i class='bx bx-check'></i> Wireframe key user flows</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="row pt-45">
                                    <h2>3. Define Solution (off-site)</h2>
                                    <p>
                                        To create a clear project implementation roadmap and determine expenses, elaborate 
                                        iteratively on the requirements and software architecture.
                                    </p>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="service-list-content">
                                            <h3>Analysis of Business</h3>
                                            <ul>
                                                <li><i class='bx bx-check'></i> Align roadmap with projections</li>
                                                <li><i class='bx bx-check'></i> Review milestones & priorities</li>
                                                <li><i class='bx bx-check'></i> Finalize business requirements</li>
                                            </ul>
                                        </div>
                                    </div>
                        
                                    <div class="col-lg-4 col-md-6">
                                        <div class="service-list-content">
                                            <h3>Architecting Solutions</h3>
                                            <ul>
                                                <li><i class='bx bx-check'></i> List the technological stack</li>
                                                <li><i class='bx bx-check'></i> Develop a vision for operations</li>
                                                <li><i class='bx bx-check'></i> Assess the implementation effort</li>
                                            </ul>
                                        </div>
                                    </div>
                        
                                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                                        <div class="service-list-content">
                                            <h3>UX Design</h3>
                                            <ul>
                                                <li><i class='bx bx-check'></i> Create a solution prototype</li>
                                                <li><i class='bx bx-check'></i> Develop a strategy for experience</li>
                                                <li><i class='bx bx-check'></i> Complete the UX solution vision</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>