<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3 style="text-align:left;">Extended Team</h3>
            <div style="color:white; text-align: left;">
                <h5 style="color:white; text-align: left;">
                    With a dependable tech partner by your side, you can grow your team and expand your software development capabilities. 
                </h5>
                </div>
            <!-- <ul> 
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Data Analytics</li>
            </ul> -->
        </div>
        
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pt-20 pb-50">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">Extended Team</span>
                        <h3>Top-tier developer talent to strengthen your development capabilities</h3>
                    </div>
                    <!-- <h3>We have 30 yearsOur strategy includes consistently evolving, to ensure we’re producing exceptional SEO for business.</h3> -->
                    <p>
                        Businesses that want to innovate and succeed with their software products must have access to additional talent pools and flexible scaling. 
                        Even if you have a well-defined project roadmap and a strong core team, additional development capabilities will allow you to meet your milestones more quickly.
                    </p>
                    <p>
                        Technocrats has been assisting companies all over the world in augmenting their software development teams with top IT talent since 2008. We can quickly find the 
                        right specialists to boost your project because we have an internal pool of 100+ tech experts and a strong employer brand.
                    </p>
                    <p>
                        Here are some of the reasons why over 65 enterprises and technology firms have chosen us as their strategic IT outsourcing partner:
                    </p>
                    <div class="about-widget-list">
                        <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>Rapid and adaptable IT staffing - scale up or down</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>Integrated teamwork at any stage of your SDLC</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>The ability to fill any skill gap in your repertoire</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>04</span>
                                <p>Years of experience in a wide range of technologies and domains</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>05</span>
                                <p>Large development facilities in the United States and Asia Pacific</p>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="about-video">
                        <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Play Video </span></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">How you gradually expand your team</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li>
                            <h3>Rapidly scale up your team <span>1</span></h3>
                            <p>Recruitment strategy that has been tried and tested. Assistance with interviewing and onboarding </p>
                        </li>
                        <li>
                            <h3>Use our extensive talent pool <span>3</span></h3>
                            <p>A pool of 100+ tech experts on staff. 750+ developers in the United States and Asia Pacific </p> 
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>2</span>Get the technical expertise.</h3>
                            <p>A meticulous screening procedure. Excellence Centers that ensure skill and service quality </p>
                        </li>
                        <li>
                            <h3><span>4</span>Integrate and accelerate your teams </h3>
                            <p>Delivery Managers with prior experience in charge of team integration. Effective knowledge transfer. </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <!-- <img src="assets/img/service/service-details.jpg" alt="Images"> -->
                        <h2>How can you benefit?</h2>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-sm-12">
                            <div class="services-content-card">
                                <h3>Timezone advantage and cultural proximity.</h3>
                                <p>
                                    It is critical to develop a mutual understanding with your entire team. 
                                    Because our development centers are in USA, we share the same business ethics and culture as our American clients.                                   
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-12">
                            <div class="services-content-card">
                                <h3>Start quickly and easily</h3>
                                <p>
                                    Because of our talent pool, proven recruitment capabilities, and strong employer brand, you can hire the first 
                                    members of your outsourced team within weeks. We will assist you in bringing your teams, technology, and business processes into alignment.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-12">
                            <div class="services-content-card">
                                <h3>Communication that is clear and timely</h3>
                                <p>
                                    Your offshore developers will work as an extension of your onsite team, reporting directly to you and participating in all of your daily 
                                    meetings. At Technocrats, we place a high value on communication and ensure that our experts are fluent in it.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-12">
                            <div class="services-content-card">
                                <h3>Development methodologies that are effective</h3>
                                <p>
                                    Your extended team will be familiar with and employ your preferred software development methodology. Technocrats are adaptable, 
                                    with an Agile mindset and extensive knowledge of Scrum, Lean, Kanban, and other methodologies. We also have prior experience working in distributed teams.
                                </p>
                            </div>
                        </div>

                        <!-- <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Create or expand your data team with top professionals.</h3>
                                <p>
                                    Create a development team from scratch or grow your internal staff by adding data specialists who offer advanced analytics services.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>AI can be used to automate and improve data analysis.</h3>
                                <p>
                                    Our experts will assist you in putting into practice ML/AI models that automate analytics, lessen manual labor, eliminate human error.
                                </p>
                            </div>
                        </div> -->
                    </div>
                    <!-- <div class="row">&nbsp;</div>

                    <h3>Create, implement, or enhance your data strategy</h3>

                    <p>Create a solid foundation for all of your data analytics efforts. Use our data analytics services to redesign your current data strategy or 
                        create a new one from scratch. In order to assist you meet all of your present and future data needs, we may also evaluate your current infrastructure.</p>

                        <div class="row">&nbsp;</div>

                        <div class="content-widget-area">
                            <div class="row">
                                <div class="col-lg-7 col-md-12">
                                    <div class="content-widget-img">
                                        <img src="assets/img/service/service-details-img2.png" alt="Images">
                                    </div>
                                </div>
    
                                <div class="col-lg-5 col-md-12">
                                    <div class="content-widget-text">
                                        <h3>Designing a data and analytics strategy:</h3>
                                        <br />
                                        <ul>
                                            <li><i class='bx bx-check'></i> Defining the scope and objectives</li>
                                            <li><i class='bx bx-check'></i> Putting data at the heart of each design choice</li>
                                            <li><i class='bx bx-check'></i> Aligning with your objectives and vision</li>
                                            <li><i class='bx bx-check'></i> Implementing collaborative data governance</li>
                                            <li><i class='bx bx-check'></i> Risk mitigation</li>
                                            <li><i class='bx bx-check'></i> Assisting in achieving a higher return on investment</li>
                                            <li><i class='bx bx-check'></i> Avoiding the need for costly development changes</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">&nbsp;</div>

                        <div class="content-widget-area">
                            <div class="row">
    
                                <div class="col-lg-6 col-md-12">
                                    <div class="content-widget-text">
                                        <h3>Strategy consulting and evaluation for data and analytics:</h3>
                                        <br />
                                        <ul>
                                            <li><i class='bx bx-check'></i> Creating the final product vision</li>
                                            <li><i class='bx bx-check'></i> Making project plans, timelines, and milestones</li>
                                            <li><i class='bx bx-check'></i> Estimating and creating functional decomposition</li>
                                            <li><i class='bx bx-check'></i> Designing CI/CD pipelines and proposing high-level architecture</li>
                                            <li><i class='bx bx-check'></i> Proposing team size, composition, and technology stack</li>
                                            <li><i class='bx bx-check'></i> Operations and support composition</li>
                                            <li><i class='bx bx-check'></i> Cost estimation</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="content-widget-img">
                                        <img src="assets/img/contact-img.png" alt="Images">
                                    </div>
                                </div>
                            </div>
                        </div>  -->

                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <div class="widget-category">
                        <h3>Other Services</h3>

                        <ul>
                            <li><a routerLink="/services-details">AI & ML Development</a></li>
                            <li><a routerLink="/services-details">Data Visualization</a></li>
                            <li><a routerLink="/services-details">Data Science</a></li>
                            <li><a routerLink="/services-details">Content Marketing</a></li>
                            <li><a routerLink="/services-details">SEO & Internet</a></li>
                        </ul>
                    </div>

                    <div class="contact-widget">
                        <h2>Contact Info</h2>

                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Phone: </h3>
                                    <span><a href="tel:+564325677896">+56432 567 7896</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a href="mailto:hello@ribo.com">hello@ribo.com</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-planet-earth"></i>
                                <div class="content">
                                    <h3>Location:</h3>
                                    <span>112/7 New York, USA</span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="services-widget-two">
                        <h2>Brochures</h2>

                        <ul>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>01:</h3>
                                    <span><a routerLink="/services-details">PDF Download</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>02:</h3>
                                    <span><a routerLink="/services-details">Services Details.txt</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

                   

<div class="data-table-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="data-table-content">
                    <h2>Drop a message to our team to see how we can help you</h2>
                    <a routerLink="/contact" class="default-btn">Get started <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="data-table-img">
                    <img src="assets/img/mission-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>