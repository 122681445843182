<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3 style="text-align:left;">Database Backup and Disaster Recovery</h3>
            <div style="color:white; text-align: left;">
                <h5 style="color:white; text-align: left;">
                    We'll help protect your business continuity through an effective database backup and disaster recovery strategy tailored to your business needs.
                </h5>
                </div>
            <!-- <ul> 
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Data Analytics</li>
            </ul> -->
        </div>
        
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-left">
                    <div class="container-max">
                        <div class="services-content">
                            <h2>Database Backup & Disaster Recovery is Essential to Business Continuity</h2>
                            <p style="text-align:left">
                                An effective database backup and Disaster Recovery (DR) strategy is vital to guaranteeing business continuity in the digital age.
                            </p>
                            <p>
                                In the event of a disaster, it’s vital to have the ability to immediately move operations to a safe environment replicated from the original. 
                                This, combined with the ability to restore lost data from an offline copy can mean the difference between minimal and major disruption. 
                                Also, under GDPR, organisations are being held more responsible than ever for their ability to recover personal data that they hold 
                                should anything happen to it.
                            </p>
                        </div>
                        <div class="services-content">
                            <h2>How we can help</h2>
                            <p style="text-align:left">
                                The Technocrats team can advise on the most effective backup and DR strategies for your organisation. 
                                Ensuring good recoverability requires having the right strategy, the most suitable tools and deploying 
                                these tools in the most appropriate configuration. Our consultants have been doing this for years and have 
                                the experience and expertise to know what works best, no matter how unique your requirements are.
                            </p>
                            <p>
                                Over the years we’ve assisted in many real DR scenarios, so we really know what works in practice. 
                                The backups we create for our clients are proactively monitored and maintained to ensure data 
                                is always easily recoverable in these situations.
                            </p>
                        </div>
                    </div>

                    <div class="row">&nbsp;</div>
                    
                    <div class="project-area">
                        <div class="container">
                            <div class="project-content">
                                <h2>Tailor-made database support solution for your specific business needs</h2>
                                <a routerLink="/contact" class="project-btn">Get started <i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    
                        <div class="project-shape">
                            <div class="shape1"><img src="assets/img/shape/shape17.png" alt=""></div>
                            <div class="shape2"><img src="assets/img/shape/shape18.png" alt=""></div>
                        </div>
                    </div>                 

                    <div class="row">&nbsp;</div>

                    <br />

                    <div class="container-max">

                        <div class="row">&nbsp;</div>

                        <div class="content-widget-area">
                            <div class="row pb-70">
                                <div class="col-lg-6 col-md-12">
                                    <div class="about-widget-img">
                                        <img src="assets/img/data-recovery.png" alt="data-recovery">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-md-12">
                                    <div class="content-widget-text">  
                                        <h2>We Build High Availability And DR Solutions</h2>                                      
                                        <ul>
                                            <li><i class='bx bx-check'></i> SQL Server Database Mirroring Solutions</li>
                                            <li><i class='bx bx-check'></i> SQL Server Always-On High Availability Groups</li>
                                            <li><i class='bx bx-check'></i> SQL Server Replication And Log Shipping Solutions</li>
                                            <li><i class='bx bx-check'></i> Oracle Data Guard Implementation</li>
                                            <li><i class='bx bx-check'></i> Oracle Real Application Cluster Solutions</li>
                                            <li><i class='bx bx-check'></i> MySQL Clustering Solutions</li>
                                            <li><i class='bx bx-check'></i> EDB Postgres Failover Manager</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="content-widget-text">  
                                        <h2>Our Approach</h2>                                      
                                        <ul>
                                            <li><i class='bx bx-check'></i> Discussing your business requirements</li>
                                            <li><i class='bx bx-check'></i> Analysing your infrastructure and it’s dependencies</li>
                                            <li><i class='bx bx-check'></i> Designing high availability solutions for your system environment</li>
                                            <li><i class='bx bx-check'></i> Presenting the system, discussing and improving</li>
                                            <li><i class='bx bx-check'></i> Implementing proof of concept if required</li>
                                            <li><i class='bx bx-check'></i> Preparing implementation plans</li>
                                            <li><i class='bx bx-check'></i> Managing changes using ITIL processes</li>
                                            <li><i class='bx bx-check'></i> Implementing and testing solutions</li>
                                            <li><i class='bx bx-check'></i> Releasing for production environments</li>
                                            <li><i class='bx bx-check'></i> Documenting and completing the change management process
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="about-widget-img" style="padding-top:80px;">
                                        <img src="assets/img/our-approach.png" alt="our-approach">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        
        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>MULTIPLE RESOURCES <span>1</span></h3>
                            <p>We assign at least 2 database specialists per project </p>
                        </li>
                        <li class="text-end">
                            <h3>QUALITY DELIVERY <span>2</span></h3>
                            <p>Peer review process will ensure that we deliver quality outcome at each stage </p>
                        </li>
                        <li class="text-end">
                            <h3>DOCUMENTATION <span>3</span></h3>
                            <p>Comprehensive designs, build and operational documents </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>3</span>ON-TIME DELIVERY</h3>
                            <p>Our team will work around the clock to ensure projects are delivered on time </p>
                        </li>
                        <li>
                            <h3><span>3</span>PROJECT REPORTING</h3>
                            <p>We use weekly project reporting and billing (for T&M method), helping you to manage projects easily </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>