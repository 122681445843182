<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3 style="text-align:left;">Database Patching and Upgrades</h3>
            <div style="color:white; text-align: left;">
                <h5 style="color:white; text-align: left;">
                    Keep your systems up-to-date and protected with help from the database experts.
                </h5>
                </div>
            <!-- <ul> 
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Data Analytics</li>
            </ul> -->
        </div>
        
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-left">
                    <div class="container-max">
                        <div class="services-content">
                            <h2>Database Patching & Upgrades keep your system running smoothly</h2>
                            <p style="text-align:left">
                                Database patching and upgrades are necessary for all databases to keep them running at their best. 
                                Older versions eventually become unsupported as the focus shifts to newer, more advanced versions based 
                                on the latest technology, which can leave your database environment open to security threats and bugs if you don’t keep it up to date.
                            </p>
                            <div class="banner-content" style="padding-top:0;padding-bottom:40px;">
                                <div class="banner-btn">
                                    <a routerLink="/contact" class="get-btn">Expert Oracle Consulting Services <i class='bx bx-chevron-right'></i></a>
                                    <a routerLink="/contact" class="call-btn">Expert SQL Server Consulting Services <i class='bx bx-chevron-right'></i></a>
                                </div>
                            </div>
                            <h2>Database upgrades future-proof your business</h2>
                            <p style="text-align:left">
                                Upgrading brings with it the benefits of the latest functionality and can help to future-proof your business. 
                                It is not compulsory to upgrade to the newest version as soon as it is released and we understand that the time 
                                is not always right to do so. Whilst upgrades can be done at a time that best suits your business, patches are 
                                released in response to bugs or security threats being discovered, so should be applied as soon as possible to protect your environment.
                            </p>
                            <p style="text-align:left">
                                We have a strong history of assisting customers with patching and upgrades, whether it’s performing the work in its entirety 
                                or lending a helping hand to an internal Database Administrator. Our best practices have been refined over many years 
                                of practical experiences, so we are able to mitigate as much risk as possible and ensure all appropriate preparation is undertaken.
                            </p>
                        </div>
                    </div>

                    <div class="row">&nbsp;</div>

                    <div class="project-area">
                        <div class="container">
                            <div class="project-content">
                                <h2>Need A Patch Or Upgrade?</h2>
                                <a routerLink="/contact" class="project-btn">Get started <i class='bx bx-plus'></i></a>
                            </div>
                        </div>

                        <div class="project-shape">
                            <div class="shape1"><img src="assets/img/shape/shape17.png" alt=""></div>
                            <div class="shape2"><img src="assets/img/shape/shape18.png" alt=""></div>
                        </div>
                    </div>

                    <div class="row">&nbsp;</div>
                    <div class="row">&nbsp;</div>
                    
                    <div class="container-max">
    
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="services-content-card">
                                    <h3>Customer Benefits</h3>
                                    <div class="content-widget-area">
                                        <div class="content-widget-text">
                                            <ul>
                                                <li><i class='bx bx-check'></i>  Reduces vulnerabilities</li>
                                                <li><i class='bx bx-check'></i> Reduces risks by running supported databases </li>
                                                <li><i class='bx bx-check'></i> Increases efficiency on information systems</li>
                                                <li><i class='bx bx-check'></i> Saves businesses time and money</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-sm-12">
                                <div class="services-content-card">
                                    <h3>Our Approach</h3>
                                    <div class="content-widget-area">
                                        <div class="content-widget-text">
                                            <ul>
                                                <li><i class='bx bx-check'></i> Understanding business requirements</li>
                                                <li><i class='bx bx-check'></i> Performing feasibility studies</li>
                                                <li><i class='bx bx-check'></i> Performing risk assessments</li>
                                                <li><i class='bx bx-check'></i> Carrying out dependency analysis</li>
                                                <li><i class='bx bx-check'></i> Managing stakeholder engagement</li>
                                                <li><i class='bx bx-check'></i> Preparing upgrade plans</li>
                                                <li><i class='bx bx-check'></i> Following ITIL change management</li>
                                                <li><i class='bx bx-check'></i> Managing upgrade projects</li>
                                                <li><i class='bx bx-check'></i> Executing upgrade tasks</li>
                                                <li><i class='bx bx-check'></i> Preparing documentation</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>