<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3 style="text-align:left;">Database Health Check</h3>
            <div style="color:white; text-align: left;">
                <h5 style="color:white; text-align: left;">
                    Comprehensive, Reportable diagonistics for your database.
                </h5>
                </div>
            <!-- <ul> 
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Data Analytics</li>
            </ul> -->
        </div>
        
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-left">
                    <div class="container-max">
                        <div class="services-content">
                            <h2>Pinpointing Poor Performance
                            </h2>
                            <p style="text-align:left">
                                A database health check examines your estate for bottlenecks, emerging issues, and opportunities to optimise performance. 
                                When rapid access to your data is essential, regular database health checks are a must.
                            </p>
                            <div class="banner-content" style="padding-top:0;padding-bottom:40px;">
                                <div class="banner-btn">
                                    <a routerLink="/contact" class="get-btn">Expert Oracle Consulting Services <i class='bx bx-chevron-right'></i></a>
                                    <a routerLink="/contact" class="call-btn">Expert SQL Server Consulting Services <i class='bx bx-chevron-right'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                    
                    <div class="container-max">
    
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="services-content-card">
                                    <h3>Why database health check is important?</h3>
                                    <div class="content-widget-area">
                                        <div class="content-widget-text">
                                            <ul>
                                                <li><i class='bx bx-check'></i>  Identify configuration issue</li>
                                                <li><i class='bx bx-check'></i> Ability to meet recovery objectives</li>
                                                <li><i class='bx bx-check'></i> Identify performance bottlenecks</li>
                                                <li><i class='bx bx-check'></i> Access disaster recovery and high availability requirements</li>
                                                <li><i class='bx bx-check'></i> Reduce the risks of vulnerabilities and downtime</li>
                                                <li><i class='bx bx-check'></i> Actionable recommendations to improve and fix issues</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">&nbsp;</div>

                    <div class="project-area">
                        <div class="container">
                            <div class="project-content">
                                <h2>Tailor-made database support solution for your specific business needs</h2>
                                <a routerLink="/contact" class="project-btn">Get started <i class='bx bx-plus'></i></a>
                            </div>
                        </div>

                        <div class="project-shape">
                            <div class="shape1"><img src="assets/img/shape/shape17.png" alt=""></div>
                            <div class="shape2"><img src="assets/img/shape/shape18.png" alt=""></div>
                        </div>
                    </div>

                    <div class="row">&nbsp;</div>

                    <div class="row">&nbsp;</div>
                    
                    <div class="container-max">
    
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="services-content-card">
                                    <h3>What do you get with the Database Health check?</h3>
                                    <div class="content-widget-area">
                                        <div class="content-widget-text">
                                            <ul>
                                                <li><i class='bx bx-check'></i>  A comprehensive report with analysis of more than 50 check points of your database environment for availability, performance, stability, robustness and security aspects.</li>
                                                <li><i class='bx bx-check'></i> Our findings and recommendations</li>
                                                <li><i class='bx bx-check'></i> Action plan to fix identified issues</li>
                                                <li><i class='bx bx-check'></i> FREE consultation to plan remediation</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-list-area pt-0 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <h2>What is included in SQL Server Database Health Check?</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>DATABASE SETTINGS</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Database options</li>
                        <li><i class='bx bx-check'></i> Data file configurations</li>
                        <li><i class='bx bx-check'></i> Encryption settings</li>
                        <li><i class='bx bx-check'></i> Fragmented index</li>
                        <li><i class='bx bx-check'></i> Outdated statistics</li>
                        <li><i class='bx bx-check'></i> Log file settings</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>PERFORMANCE REVIEW</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Wait event analysis</li>
                        <li><i class='bx bx-check'></i> Missing indexes</li>
                        <li><i class='bx bx-check'></i> Analyse indexes</li>
                        <li><i class='bx bx-check'></i> Blocking and deadlocks</li>
                        <li><i class='bx bx-check'></i> Identify slow running queries</li>
                        <li><i class='bx bx-check'></i> I/O, CPU and memory bottlenecks</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>SECURITY CONFIGURATION</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Privileged users</li>
                        <li><i class='bx bx-check'></i> SA user settings</li>
                        <li><i class='bx bx-check'></i> Auditing</li>
                        <li><i class='bx bx-check'></i> TDE and backup settings</li>
                        <li><i class='bx bx-check'></i> Investigate unauthorized access</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-12 col-md-12">
                <div class="service-list-content">
                    <h3>INSTANCE CONFIGURATION</h3>
                    <p></p>
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <ul>
                                <li><i class='bx bx-check'></i> Memory options</li>
                                <li><i class='bx bx-check'></i> Workload settings</li>
                                <li><i class='bx bx-check'></i> Tempdb files</li>
                                <li><i class='bx bx-check'></i> Data file location and configuration</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <ul>
                                <li><i class='bx bx-check'></i> Configured trace flags</li>
                                <li><i class='bx bx-check'></i> Performance options</li>
                                <li><i class='bx bx-check'></i> Compatibility settings</li>
                                <li><i class='bx bx-check'></i> User rights assignment</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <ul>
                                <li><i class='bx bx-check'></i> Recovery models</li>
                                <li><i class='bx bx-check'></i> Email settings</li>
                                <li><i class='bx bx-check'></i> Alerts and notifications</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-12 col-md-12">
                <div class="service-list-content">
                    <h3>HOST CONFIGURATION</h3>
                    <p></p>
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <ul>
                                <li><i class='bx bx-check'></i> Disk layout and allocation</li>
                                <li><i class='bx bx-check'></i> Memory and CPU</li>
                                <li><i class='bx bx-check'></i> Swap setting</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <ul>
                                <li><i class='bx bx-check'></i> Performance options</li>
                                <li><i class='bx bx-check'></i> Security policy settings</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <ul>
                                <li><i class='bx bx-check'></i> User rights assignment</li>
                                <li><i class='bx bx-check'></i> Cluster settings</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>ANALYSE LOGS</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Windows event log</li>
                        <li><i class='bx bx-check'></i> SQL Server logs</li>
                        <li><i class='bx bx-check'></i> SQL Server agent log</li>
                        <li><i class='bx bx-check'></i> Cluster logs</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>BACKUP AND RECOVERY</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Backup jobs and configuration</li>
                        <li><i class='bx bx-check'></i> Missing backups</li>
                        <li><i class='bx bx-check'></i> Log backup settings</li>
                        <li><i class='bx bx-check'></i> RTO and RPO review</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>HIGH AVAILABILITY AND DR</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Database mirroring</li>
                        <li><i class='bx bx-check'></i> AlwaysOn settings</li>
                        <li><i class='bx bx-check'></i> Cluster settings</li>
                        <li><i class='bx bx-check'></i> Log Shipping settings</li>
                        <li><i class='bx bx-check'></i> Replication configuration</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-list-area pt-0 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <h2>What is included in Oracle Database Health Check?</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>HOST CONFIGURATION</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Kernel parameters</li>
                        <li><i class='bx bx-check'></i> Memory and CPU</li>
                        <li><i class='bx bx-check'></i> Disk layout and file system</li>
                        <li><i class='bx bx-check'></i> Disk space</li>
                        <li><i class='bx bx-check'></i> Network configuration</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>PATCH AND VERSION REVIEW</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Check applied patches</li>
                        <li><i class='bx bx-check'></i> Compare with life cycle support options</li>
                        <li><i class='bx bx-check'></i> Review patching strategy</li>
                        <li><i class='bx bx-check'></i> Identify missing patches</li>
                        <li><i class='bx bx-check'></i> Review upgrade strategy</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>SCHEMA AND DATABASE</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Analyse indexes and tables</li>
                        <li><i class='bx bx-check'></i> Tablespace setting, free space & growth</li>
                        <li><i class='bx bx-check'></i> Invalid objects</li>
                        <li><i class='bx bx-check'></i> Fragmentations</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>INSTANCE CONFIGURATION</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Review database parameters</li>
                        <li><i class='bx bx-check'></i> Memory settings</li>
                        <li><i class='bx bx-check'></i> Redo and archivelog configuration</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>BACKUP AND RECOVERY</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Review backup strategy</li>
                        <li><i class='bx bx-check'></i> Evaluate RTO and RPO</li>
                        <li><i class='bx bx-check'></i> RMAN settings</li>
                        <li><i class='bx bx-check'></i> Retention and recoverability</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>HIGH AVAILABILITY AND DR</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Review high availability and DR strategy</li>
                        <li><i class='bx bx-check'></i> Review RAC configuration</li>
                        <li><i class='bx bx-check'></i> Review data guard settings</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>SECURITY</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Privileged users and permissions</li>
                        <li><i class='bx bx-check'></i> TDE and backup encryption</li>
                        <li><i class='bx bx-check'></i> Analyse transport layer security</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>ANALYSE LOG FILES</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Check alert log</li>
                        <li><i class='bx bx-check'></i> Check listener log</li>
                        <li><i class='bx bx-check'></i> Check OS level logs</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>LICENSE REVIEW</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Review existing license</li>
                        <li><i class='bx bx-check'></i> Discuss options to reduce TCO</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>PERFORMANCE TUNING</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> Analyse AWR reports</li>
                        <li><i class='bx bx-check'></i> Analyse ADDM, ASH reports</li>
                        <li><i class='bx bx-check'></i> Analyse OSWatcher</li>
                        <li><i class='bx bx-check'></i> Investigate disk/CPU intensive queries</li>
                        <li><i class='bx bx-check'></i> Identify options for SQL tuning</li>
                        <li><i class='bx bx-check'></i> Analyse database advisor reports</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>REVIEW ADVISORS</h3>
                    <p></p>
                    <ul>
                        <li><i class='bx bx-check'></i> PGA Target Advice</li>
                        <li><i class='bx bx-check'></i> TDE and backup encryption</li>
                        <li><i class='bx bx-check'></i> DB Cache Target Advice</li>
                        <li><i class='bx bx-check'></i> Shared Pool Target Advice</li>
                        <li><i class='bx bx-check'></i> Memory/SGA Target Advice</li>
                        <li><i class='bx bx-check'></i> Segment advice</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>